/* eslint-disable */
import React from 'react';
import { Container, Row, Col } from 'reactstrap';

const Footer = () => {
    return (
        <div className="footer4 b-t spacer">
            <Container>
                <Row>
                    <Col lg="3" md="6" className="footer-text">
                        <h5 className="footer-text">Origin</h5>
                        <p>Budapest University of Technology</p>
                    </Col>
                    <Col lg="3" md="6" className="footer-text">
                        <h5 className="footer-text">Phone</h5>
                        <p>Management: +36 30 649 9479</p>
                    </Col>
                    <Col lg="3" md="6" className="footer-text">
                        <h5 className="footer-text">Email</h5>
                        <p>Contact us:  <a href="#" className="footer-text">ask.startapp@gmail.com</a></p>
                    </Col>
                    <Col lg="3" md="6">
                        <h5 className="footer-text">Social</h5>
                        <div className="round-social light">
                            {/*}
                            <a href="#" className="link"><i className="fa fa-facebook"></i></a>
                            <a href="#" className="link"><i className="fa fa-twitter"></i></a>
                            <a href="#" className="link"><i className="fa fa-google-plus"></i></a>
                            <a href="#" className="link"><i className="fa fa-youtube-play"></i></a>
                            */}
                            <a href="https://www.instagram.com/start__events" className="link"><i className="fa fa-instagram"></i></a>
                        </div>
                    </Col>
                </Row>
                <div className="f4-bottom-bar">
                    <Row>
                        <Col md="footer-text">
                            <div className="d-flex font-14 justify-content-between">
                                <div className="m-t-10 m-b-10 copyright">All Rights Reserved by stArt. The Banner image was taken by Drucker Dávid, Trafó.</div>
                                <div className="links ms-auto m-t-10 m-b-10">
                                    <a href="https://drive.google.com/file/d/1LtZRaqrIqD2O2NXRs1u8dsfIVdHmyNHg/view?usp=drive_link" className="p-10 p-l-0">Terms of Use</a>
                                    <a href="https://drive.google.com/file/d/1LtZRaqrIqD2O2NXRs1u8dsfIVdHmyNHg/view?usp=drive_link" className="p-10">Legal Disclaimer</a>
                                    <a href="https://drive.google.com/file/d/1LtZRaqrIqD2O2NXRs1u8dsfIVdHmyNHg/view?usp=drive_link" className="p-10">Privacy Policy</a>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>
        </div>
    );
}
export default Footer;
