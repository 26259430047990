import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { Container, Row, Col } from 'reactstrap';

import img1 from '../../assets/images/team/linda.png';
import img2 from '../../assets/images/team/tamas.JPG';
import img3 from '../../assets/images/team/Csongor.png';



const TeamPage = () => {
    return (
        <div
            style={{
                padding: '20px',
                textAlign: 'center',
                background: 'linear-gradient(200deg, #6BE585, #4A4A4A, #4A4A4A, #6C63FF)',
                minHeight: '100vh', // Ensures the background covers the full height of the page,
                width: '100vw',
            }}
        >
            <h1 style={{ color: 'white' }}>Meet the Team</h1>
            <p style={{ color: 'white' }}>Welcome to the team page! Here, you can find information about our team members.</p>
            <Container>
                <Row>
                    <Col lg="4" className="m-b-30" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <h4 className="card-title" style={{ color: 'white' }}>Liao Linda</h4>
                        <h6 className="card-subtitle"><code style={{ color: 'white' }}>UI/UX designer</code></h6>
                        <img
                            src={img1}
                            alt="img"
                            style={{ maxWidth: '400px', width: '65vw', maxHeight: '400px', objectFit: 'cover' }}
                            className="img-responsive img-rounded"
                        />
                        <h7 style={{ color: 'white' }}>
  As a UI/UX designer, my passion lies in creating intuitive and visually engaging interfaces that enhance user experiences.
</h7>
                        <p className="m-t-15 m-b-0"></p>
                    </Col>
                    <Col lg="4" className="text-center m-b-30" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <h4 className="card-title" style={{ color: 'white' }}>Kotán Tamás</h4>
                        <h6 className="card-subtitle"><code style={{ color: 'white' }}>Project Leader & Software Developer</code></h6>
                        <img
                            src={img2}
                            alt="img"
                            style={{ maxWidth: '400px', width: '65vw', maxHeight: '400px', objectFit: 'cover' }}
                            className="img-responsive img-rounded"
                        />
                        <h7 style={{ color: 'white' }}>
  As the Project Leader, I lead our team with a vision to bring a new color to the cultural life of Budapest, meanwhile steering the team towards achieving our strategic goals.
</h7>
                        <p className="m-t-15 m-b-0"></p>
                    </Col>
                    <Col lg="4" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <h4 className="card-title" style={{ color: 'white' }}>Pup Csongor</h4>
                        <h6 className="card-subtitle"><code style={{ color: 'white' }}>Software Developer</code></h6>
                        <img
                            src={img3}
                            alt="img"
                            style={{ maxWidth: '400px', width: '65vw', maxHeight: '400px', objectFit: 'cover' }}
                            className="img-responsive img-rounded"
                        />
                        <h7 style={{ color: 'white' }}>
  As a Software Developer, I build and optimize our solutions in the app to serve all the needs of our users and partners.
</h7>
                        <p className="m-t-15 m-b-0"></p>
                    </Col>
                </Row>
            </Container>
            <p style={{ color: 'white' }}>If you want to feature your events on our app or have any questions, feel free to contact us. ask.startapp@gmail.com</p>
            <Link to="/">
                <button className="DownloadButton">
                    Go Back to Landing Page
                </button>
            </Link>
        </div>
    );
};

export default TeamPage;
