import React from 'react';
import {Container, Row, Col, NavbarBrand} from 'reactstrap';
import logo from "../../assets/images/logos/white-text.png";
import android_logo from "../../assets/images/logos/android_logo.png";
import apple_logo from "../../assets/images/logos/apple_logo.png";



const HeaderBanner = () => {
    return (
        <div className="static-slider-head">
            <Container>
                <Row className="justify-content-center">
                    <Col lg="8" md="6" className="align-self-center text-center">
                        <NavbarBrand href="/"><img src={logo} alt="wrapkit" style={{ width: '350px', height: 'auto' }} /></NavbarBrand>
                        <h1 className="title" style={{ fontFamily: 'Impact, Haettenschweiler, Arial, sans-serif', wordSpacing: '16px', letterSpacing: '6px' }}>
                            Your night starts here.
                        </h1>
                        <div className="button-container">
                            <button className="DownloadButton_android" onClick={() => window.location.href='https://play.google.com/store/apps/details?id=com.kotantamas.start'}>
                                <img src={android_logo} alt="Logo" className="ButtonLogo_android"/>
                                Download
                            </button>
                            <button className="DownloadButton_apple" onClick={() => window.location.href='https://apps.apple.com/hu/app/start-events/id6670304366'}>
                                <img src={apple_logo} alt="Logo" className="ButtonLogo_apple"/>
                                Download
                            </button>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default HeaderBanner;
