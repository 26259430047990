import React from 'react';
import Header from "../../components/header/header";
import HeaderBanner from "../../components/banner/banner";
import Buttons from "../components/sections/buttons";
import Labels from "../components/sections/labels";
import PagePagination from "../components/sections/pagination";
import Images from "../components/sections/images";
import Breadcrumbs from "../components/sections/breadcrumbs";
import Cards from "../components/sections/cards";
import Dropdowns from "../components/sections/dropdowns";
import PageForm from "../components/sections/form";
import PageTable from "../components/sections/table";
import Notification from "../components/sections/notification";
import TooltipPopover from "../components/sections/tooltip-popover";
import Typography from "../components/sections/typography";
import JsComponents from "../components/sections/js-components";
import CallToAction from "../../components/call-to-action/CallToAction";
import Footer from "../../components/footer/footer";

function LandingPage() {
    return (
        <div className="landing-background">
            <Header/>
            <div className="page-wrapper">
                <div className="container-fluid">
                    <HeaderBanner />
                    <text className="introduction"> "START ASSISTS ART ENTHUSIASTS IN STAYING UPDATED WITH THE RAPIDLY
                        EXPANDING ARRAY OF EVENTS, ALLOWING THEM TO EXPERIENCE THE FULL
                        SPECTRUM OF CULTURAL OPPORTUNITIES THAT THE CITY OFFERS. FINDING THE
                        RIGHT PROGRAM MIGHT TAKE HOURS IN THE NIGHTLIFE OF A METROPLOIS. SO
                        DON'T FIND THE RIGHT EVENT, LET THE RIGHT EVENT FIND YOU." </text>
                </div>
            </div>
            <Footer/>
        </div>);
}

export default LandingPage;